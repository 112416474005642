import { Envs, Utils } from '../common';
const oldUIProducts = {
	//'1AN': {
	//	YASPAQZR: '001' //易安食品安全责任保险
	//},
	//HUAGUI: {
	//	HGDMDQSXMTJ: '001' //华贵大麦定期寿险(免体检版)
	//}
} as { [key: string]: { [key: string]: string } };

export const onCSMSHandled = (options: {
	vendorCode: string | undefined;
	productCode: string | undefined;
	productVersion: string | undefined;
}): boolean => {
	const { vendorCode = '', productCode = '', productVersion } = options;
	return (oldUIProducts[vendorCode] || {})[productCode] === productVersion;
};

// 忽略校验合约的产品
const ignoreAgreementCheckProducts = {
	hongk: {
		HKXGZX: '001' //弘康佑安意外保险
	}
} as { [key: string]: { [key: string]: string } };

export const ignoreAgreementCheck = (options: {
	vendorCode: string | undefined;
	productCode: string | undefined;
	productVersion: string | undefined;
}): boolean => {
	const { vendorCode = '', productCode = '', productVersion } = options;
	return (ignoreAgreementCheckProducts[vendorCode] || {})[productCode] === productVersion;
};


//可回溯产品定义
const GluttonProducts = {
	FXJK: {
		'FXLJYS2019': { //复星联合乐健一生中端医疗保险（2019版）
			'001': {
				EPLBKS: true,
			}
		},
		'FXMMBB': { //复星联合妈咪保贝少儿重大疾病保险
			'001': {
				EPLBKS: true,
			}
		},
		'FXMMBBXSB': { //复星联合妈咪保贝（新生版）少儿重大疾病保险
			'001': {
				EPLBKS: true,
			}
		},
		'FXBJX': { //复星联合倍吉星重大疾病保险
			'001': {
				EPLBKS: true,
			}
		},
		'FXYSWY2021': { //复星益生无忧传染病医疗保险
			'001': {
				EPLBKS: true,
			}
		},
		'FXAWYYWSH': { //复星爱无忧意外伤害
			'001': {
				EPLBKS: true,
			}
		},
		'FXJYB': { //复星家医保重大疾病保险
			'001': {
				EPLBKS: true,
			}
		},
		'FXKLES': { //复星康乐一生（2021版）重大疾病保险
			'001': {
				EPLBKS: true,
			}
		},
		'FXLHCYB2020': { //复星超越保2020
			'001': {
				EPLBKS: true,
			}
		},

		// 'FXLXYSYL': { //这款产品没有上
		// 	'001': {
		// 		EPLBKS: true,
		// 	}
		// }
	},
	'1AN': {
		'YAZYWYBZJH': true, //易安住院无忧保障计划
		'YAZHJTYWX': true,//  - 综合交通工具意外险
	},
	RHASSURANCE: {
		'RHBJLBDCJFZDJBBX': true,// - 瑞华-倍嘉乐保多次给付重大疾病保险
	},
	APIINS: {
		'YXFEJZHBZ': true, //亚太财险“永幸福”E家综合保障保险
		'NETM5270IC': true,
	},
	HIINS: {
		'HIINSRZZBX': true,//现代财产保险（中国）认知症保险
	},
	axa: {
		'AXAZYSHBWZYYLBX': true,//【安盛天平】卓越守护百万住院医疗保险（高血压患者专款）
		'AXAZYWYBWFABX2020': true,//【安盛天平】卓越无忧百万防癌保险2020版
		'AXAZYSHBWZYYLBXTNB': true,//【安盛天平】卓越无忧百万住院医疗保险（糖尿病专款）
		'AXAZYSHBWYLBX': true,//【安盛天平】卓越无忧百万住院医疗保险
		'AXAXYHGRYW': true,//安盛天平夕阳红个人意外伤害保险（2021版）
		'AXAZYBWGRYW': true,//安盛天平"卓越百万"个人意外伤害保险
		'AXAGRZHZYYL': true,//安盛天平个人综合住院医疗保险
		'AXAZYSHBWZYYL2022PLUSHLW': true,//安盛天平卓越守护百万住院医疗2022升级版
		'AXASHSGGRYW': true,//【安盛天平】安盛天平守护时光个人意外伤害保险(互联网专属)
		'AXAZYXXYLBX2023HLW': true,//【卓越馨选医疗保险(2023版)(互联网专属)
		'AXAZYLXJHLW': true,//安盛天平卓越旅行家（互联网专属）
		'AXAZXTXLXBXHLW': true,//安盛天平智行天下旅行保险（2018版）（互联网专属）
		'AXAZYSHBWZYYL2022HLW': true,//安盛天平卓越守护百万住院医疗2022版
		'AXASHWLXPX': true, //“守护未来”安盛学生平安保险
		'NETM9353FB': true,//卓越馨选医疗保险(2024版)(互联网专属)
	},
	jdallianz: {
		'JDALGDETYLBX': true,//成长优享-高端儿童医疗保险（2021版）
		'JDALZABWYLBX': true,//京东安联臻爱百万医疗保险计划(甲状腺病特别版)
		'JDBDWYWGZ': true,//京不倒翁（新版）- 京东安联中老年人意外骨折保障计划
		'JDETYSL': true,//成长优享-京东安联私立儿童眼视力保险保障计
		'JDXXYMY': true,//小幸孕 - 京东安联母婴保障计划
		'JDJCYJGXBE': true,//京东安联京彩一家共享保额意外险保障计划
		'JDSLETCK': true,//成长优享-京东安联私立儿童齿科保险保障计划
	},

	HQ: {
		'HQYHJSZEZS': true,//横琴永恒金生增额终身寿险
		'HQCSYJZEZS': true,//横琴传世赢家增额终身寿险
		'HQYSDZDJBBX': true,//横琴优时代重大疾病保险
		'HQCSYHZEZS': true,//横琴传世壹號增额终身寿险
		'HQZXYSYLNJ': true,//横琴臻享一生养老年金保险
		'HQYABZSSX': true,//横琴优爱宝终身寿险
		'HQCSYHZXBZEZS': true,//横琴传世壹号尊享版终身寿险
	},
	HUAGUI: {
		HGDMDQSX2021: true, //华贵大麦2021定期寿险(免体检版)
		HGXAZSS: true, //华贵小爱终身寿险
		HGTMJ2021: true, //华贵大麦甜蜜家2021定期寿险
		HGDM2021TJB: true, //华贵大麦2021定期寿险体检版
		HGXATJB: true, //华贵小爱终身寿体检版
		HGQQNB: true, //华贵大麦全能保险
		HGDMDLB: true//兜来保
	},
	KUNLUN: {
		'KLJKBDBB': true,//健康保（多倍版）重大疾病保险
		'KLJKBPHDBB': true,//健康保普惠多倍版重大疾病保险
		'KABJBBZJH': true,//康爱宝疾病保险
	},
	SHLIFE: {
		'SHRSYXZS': true,//上海人寿优选终身寿险
	},
	xint: {
		'XTRYRSSHYXBZDJBBX': true,//信泰如意人生守护（英雄版）重大疾病保险
		'XTRYZZSSX': true,//信泰如意尊终身寿险
		'XTRYZZSSX2': true,//信泰如意尊（2.0版）终身寿险 暂时先关闭
	},
	CHINALIFE: {
		TAXYLBX2020: true,//特安心医疗保险（2020版）
		AXG: true,//安心雇
		AXYSXB: true,//安心养舒心版
		BWAXL: true,//百万安心疗
		JLB: true,//家乐宝
		JYRQZHBX: true,//家用燃气综合保险
		XHZX: true,//熊孩子险
	},
	starrchina: {
		SDCQSBWYWBZJH: true,//史带永达理常青树百万意外保障计划
		SDCAZYJNLX: true,//史带永达理爱自由境内旅行保障计划3代
		SDCLNRYWBZ: true,//史带永达理老年人意外保障
		SDJNDTZBY: true,//史带永达理境内周边游旅行保障计划
		SDJNHWYD: true,//史带永达理户外运动
	},
	hongk: {
		HKXGZX: true //弘康佑安意外保险
	},
	CLL: {
		LJZGTYTHRZSSX: true
	},
	futurebaobei: {
		YCRYB: true //永诚任医保
	},
	AIXIN: {
		AXRSXXSNJBXWX: true,//爱心人寿心相随年金保险
		AXRSYLDYLNJBX: true,//爱心人寿养乐多养老年金保险
		AXRSYSHZEZSSX: true, //爱心映山红增额终身寿
		AXRSSHSCQYWX: true,//爱心人寿守护神长期意外险
	},
	PAMHI: {
		PAESBCQGRZYYL: true, // 平安e生保长期个人住院医疗
		PAESBCQGRZYYLHLW: true, // 平安e生保长期个人住院医疗(互联网版)
		PAESMBYLZH2023: true, // 平安e生保·慢病医疗险组合产品2023(互联网版)
		MJPAESMBYLZH2023: true, // 平安e生保·慢病医疗险组合产品2023(互联网版)
		PAESCQYL: true, // 平安e生保长期医疗
		ZHPAESBHLWCQYLBX: true, // 平安e生保互联网长期医疗保险（费率可调）产品组合
		ZHPAHLWZSFAFLKT: true, // 平安互联网终身防癌（费率可调）产品组合
		ZHPAESB2023MBBHLWBX: true, // 平安e生保2023（慢病版）互联网保险产品组合
		PAESFZJHLW: true, //平安e生福·重疾险（1年期）互联网专属保险产品组合
		NETM8075LP: true, //平安e生保 •互联网慢病医疗2023保险产品组合
		NETM6201TY: true, //平安e生保•互联网长期医疗（费率可调）保险产品组合
	},
	zhongan: {
		ZXES2021: true, //尊享e生2021版
		NXBYWX: true,   //暖心保意外险
		NETM7810GK: true //众安宠物险
	},
	dingcheng: {
		dcdingfeng1hao: true,//鼎诚鼎峰1号终身寿险
	},
	fubang: {
		FBGAESFA: true,//富邦关爱e生·防癌医疗险
		FBXFEJBW: true,//富邦幸福e家·百万医疗险
		FBJJAXBJBX: true,//居家安心保（基本型）
	},
	TAIKANG_GI: {
		TKZXECKPH: true, //泰康E齿康588普惠计划
		TKZXECKPHHLWH5: true, //泰康E齿康588普惠计划(H5对接)
	},
	CPICGIA: {
		CPICJNTRAC: true, // 太平洋境内旅游意外险
		NETM5635EW: true, // 小蜜蜂3号综合意外险（互联网专属）
		NETM2151DF: true, //小蜜蜂5号综合意外险
	},
	HUIZE: {
		HUIXINANSEHLW2022: true,//慧馨安2022少儿重疾险（互联网专属）
		NETM6004PV: true, //小蜜蜂(轻享版)综合意外险
		NETM5635EW: true, // 小蜜蜂3号综合意外险（互联网专属）
		NETM2151DF: true, //小蜜蜂5号综合意外险
		NETM2008WD: true, //守卫者6号重大疾病保险
		NETM2154YF: true, //小医仙2号医疗险
		GFDEWHLW6: true, //达尔文6号重大疾病保险（互联网专属）
		NETM9393AQ: true, // 达尔文10号重大疾病保险 
		NETM9932HF: true, // 小青龙5号少儿重大疾病保险（互联网）
		NETM8077PN: true, // 小医仙3号医疗险
		NETM3845UK: true, // 华贵大麦2024定期寿险（互联网专属）
		NETM6195IK: true, // 长相安长期医疗险(20年保证续保)个人版
		NETM1267VT: true, // 长相安长期医疗险(20年保证续保)家庭版
		NETM4390CJ: true, // 暖宝保3号少儿门急诊保险
		NETM0324YT: true, // 中荷互联网岁岁享2.0护理保险
		NETM1459TA: true, // 小淘气5号少儿重大疾病保险
		NETM0268TH: true, // 快享福2号终身年金保险
		NETM7862QC: true, // 小神童5号少儿意外险
		NETM1420EY: true, // 孝心安5号老年人意外险
		NETM9878XQ: true, // 长相安长期医疗险(20年保证续保)个人版（接力计划）
		NETM6639ND: true, // 长相安长期医疗险(20年保证续保)家庭版（接力计划）
	},
	CPICHE: {
		NETM7251CS: true, //蓝医保·终身防癌医疗险
	},
	PINGANGI: {
		NETM6244AG: true, //平安青海老年人意外险标准版(互联网)
		NETM6004PV: true, //小蜜蜂(轻享版)综合意外险
		MITM4820HC: true,
		NETM5561YP: true,
		NETM4911UZ: true,
	},
	CHUANGXIN: {
		NETM7091AW: true, //  北京人寿大黄蜂9号少儿重疾险
		NETM9017PS: true, //中国人保金医保百万医疗险
		NETM5782XB: true, //北京人寿大黄蜂10号少儿重疾险(全能版)
		NETM4953CZ: true, //中国人保金医保少儿长期医疗险(0免赔)产品组合
		NETM0988AU: true, //中国人保大护甲6号意外险(旗舰版)
		NETM4931MH: true, //复星保德信大黄蜂10号少儿重疾险
		NETM5980WN: true, //  君龙人寿超级玛丽9号重疾险
		NETM0528FQ: true, //君龙人寿超级玛丽10号重疾险
		NETM3990CZ: true, //君龙人寿超级玛丽11号重疾险
		NETM9589RZ: true, //北京人寿擎天柱8号定期寿险
		NETM1082EZ: true, //华泰保险一年期少儿重疾险2021
		NETM8218RE: true, //华泰保险一年期成人重疾险2021
		NETM9613VP: true,//北京人寿大黄蜂11号少儿重疾险(全能版)
		NETM3350RK: true, // 北京人寿擎天柱9号定期寿险(互联网专属)
		NETM4857JG: true, // 中国人保金医保2号长期百万医疗险
		NETM8346NQ: true, // 北京人寿大黄蜂12号焕新版重大疾病保险
		NETM6980BF: true, // 君龙人寿超级玛丽12号重大疾病保险
		NETM5011IR: true, // 中国平安小顽童6号少儿意外险
		NETM1659QB: true, // 阳光人寿互联网定期恶性肿瘤疾病保险
		NETM8219TA: true, // 海保人寿福多多1号重大疾病保险(福享版)
		NETM2503ZU: true, // 太平洋保险大护甲5号意外险（家庭版）
		NETM7236DJ: true, // 太平洋保险大护甲6号意外险（家庭版）
	},
	PICCGI: {
		NETM1370TQ: true,
		NETM0988AU: true,
	},
	CHIC: {
		NETM9848QR: true, //肺康宝·肺部恶性肿瘤医疗保险 
	},
	liberty: {
		NETM8192BS: true,
	},
	zhongdal: {
		ALAXWLJWLXQQBYWX: true, //安联安行万里境外旅行险（互联网）全球版
		ALAXWLJWLXOZBYWX: true, //安联安行万里境外旅行险（互联网）欧洲版
		ALAXWLJWLXDNYYWX: true, //安联安行万里境外旅行险（互联网）东南亚版
	},
	HTPC: {
		NETM3891QK: true, //AON尊长无忧老年意外险
	},
	trustlife: {
		MITM9807PR: true, //信美相互医家医保障计划
	},
} as object;

export const onGluttonHandled = (options: {
	vendorCode: string;
	productCode: string;
	productVersion: string;
}): boolean => {
	const { vendorCode, productCode, productVersion } = options;
	if (!vendorCode) {
		return false;
	}
	//@ts-ignore
	const vendorCodeValue = GluttonProducts[vendorCode] as any;
	if (Utils.isBoolean(vendorCodeValue)) {
		return vendorCodeValue;
	}
	if (!vendorCodeValue) {
		return false;
	}
	if (!productCode) {
		return false;
	}
	const productCodeValue = (vendorCodeValue || {})[productCode];
	if (Utils.isBoolean(productCodeValue)) {
		return productCodeValue;
	}
	if (!productCodeValue) {
		return false;
	}
	if (!productVersion) {
		return false;
	}
	const productVersionValue = (productCodeValue || {})[productVersion];
	if (Utils.isBoolean(productVersionValue)) {
		return productVersionValue;
	}
	if (!productVersionValue) {
		return false;
	}
	const { tenantCode } = Envs.findAccount();
	if (!tenantCode) {
		return false;
	}
	const tenantValue = (productVersionValue || {})[tenantCode];
	if (Utils.isBoolean(tenantValue)) {
		return tenantValue;
	}
	if (!tenantValue) {
		return false;
	}
	return false;
}
